<style>
  .chip {
    background-color: rgba(27, 31, 35, 0.08);
    border-radius: 20px;
    color: #586069;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    padding: 2px 5px;
  }
</style>

<div class="chip">
  <slot />
</div>
