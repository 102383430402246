<style>
  .divider {
    background-color: #e1e4e8;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
</style>

<div class="divider" />
